import React from "react";
import "./App.css";
import { Route, BrowserRouter as Router } from "react-router-dom";
import Layout from "./components/Layout";
import MainLoader from "./assets/main_loader.gif";
import ApiLoader from "./assets/loading_icon.gif";
import { connect } from "react-redux";
import $ from "jquery";

// Disable right click and F12 buttons from inspecting elements
document.addEventListener("contextmenu", event => event.preventDefault());

$(document).keydown(function (event) {
    if (event.keyCode === 123) {
        // Prevent F12
        return false;
    } else if (event.ctrlKey && event.shiftKey && event.keyCode === 73) {
        // Prevent Ctrl+Shift+I
        return false;
    }
});

class App extends React.Component {
    constructor(props) {
        super(props);
        this.updateLoader = this.updateLoader.bind(this);
    }

    updateLoader = () => {
        $(".loader_container").addClass("d-none");
        $("#main_container").removeClass("d-none");
    };

    render() {
        window.addEventListener("load", this.updateLoader);

        const { main_loader } = this.props;

        // Display loader when api is loading
        let loader = "";

        if (main_loader) {
            loader = (
                <div className="api_loader_container">
                    <img
                        src={ApiLoader}
                        height="100"
                        className="img-fluid loader_image"
                        alt="Loading . . ."
                    />
                </div>
            );
        } else {
            loader = "";
        }

        return (
            <div>
                {loader}

                {/* Display loader when page is not loaded */}
                <div className="loader_container">
                    <img
                        src={MainLoader}
                        height="180"
                        className="loader_image"
                        alt="Loading . . ."
                    />
                </div>

                <div className="container-fluid d-none"  id="main_container">
                    <Router>
                        <Route exact path="/" component={Layout} />
                    </Router>
                </div>
            </div>
        );
    }
}

const mapStatesToProps = state => {
    return {
        main_loader: state.home.main_loader
    };
};

export default connect(mapStatesToProps)(App);
