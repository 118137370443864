import React from "react";
import "./Pricing.css";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getMembershipPlans } from "../../actions/Listings";
import PopularIcon from "../../assets/popular.png";
import SaleIcon from "../../assets/sale.png";
import { addRemoveCart } from "../../actions/Home";
import $ from "jquery";
import server from '../../utils/Server';
class Pricing extends React.Component {
    constructor(props) {
        super(props);
        var formdata=new FormData();
        formdata.append('page',6);
        server.post('/counter',formdata)
        .then((response) => {
         console.log(response.data.message);
        })
        localStorage.setItem('route','/pricing');
        this.handleLoginClick = this.handleLoginClick.bind(this);
    }

    componentWillMount = () => {
        this.props.onGetMemberPlans();
    };

    _handleAddRemoveCart = (index, plan_id) => {
        const { member_plans } = this.props;
        member_plans[index].cart_status = !member_plans[index].cart_status;
        this.setState({ member_plans: member_plans });
        var formData = new FormData();
        formData.append("plan_id", plan_id);

        this.props.onAddRemoveCart(formData);
    };

    handleLoginClick = e => {
        // Show login page
        $(".login_container")
            .removeClass("d-none")
            .removeClass("slideOutRight")
            .addClass("slideInRight");
    };

    render() {
        const { is_auth, benefits, member_plans } = this.props;

        return (
            <div className="container-fluid pricing_container min_height">
                <div className="row states_container pt-4 mt-5">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pt-4 text-center">
                        <div className="row sort_container">
                            <div className="col"></div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                <h3 className="text-center text-orange text-uppercase">
                                    {" "}
                                    CHOOSE YOUR BEST PLANS{" "}
                                </h3>
                                <br/>
                                <br/>
                            </div>
                            <div className="col text-right">{/*  */}</div>
                        </div>
                    </div>
                </div>

                {/* membership plans display */}
                <div className="row justify-content-center mt-3">
                    {member_plans && member_plans.length > 0
                        ? member_plans.map((plan, index) => (
                              <div
                                  key={plan.id}
                                  className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 mb-5 text-center d-flex">
                                  <div className="pricing_package animate-pulse w-100">
                                      <div className="package_header">
                                          <h4 className="text-uppercase mt-4">
                                              {" "}
                                              {plan.plan_name}{" "}
                                          </h4>
                                          {plan.offer_price > 0 
                                          &&
                                           plan.offer_price < plan.plan_price 
                                          ? (
                                              <div>
                                                  <span className="actual_price">
                                                      {" "}
                                                      <i className="fa fa-rupee"></i>{" "}
                                                      {plan.plan_price}{" "}
                                                  </span>
                                                  <br />
                                                  <h1>
                                                      {" "}
                                                      <i className="fa fa-rupee"></i>{" "}
                                                      {plan.offer_price}{" "}
                                                      <img
                                                          src={SaleIcon}
                                                          alt="Offer"
                                                          className="offer_banner"
                                                      />{" "}
                                                  </h1>
                                                  {/* {plan.offer_name !== '' ? <span className="small_text"><strong> {plan.offer_name} {Math.round((plan.plan_price - plan.offer_price) / plan.plan_price * 100 )}% off. </strong></span> : ''} */}
                                                  {plan.offer_name !== "" ? (
                                                      <span className="small_text">
                                                          <strong>
                                                              {" "}
                                                              {
                                                                  plan.offer_name
                                                              }{" "}
                                                          </strong>
                                                      </span>
                                                  ) : (
                                                      ""
                                                  )}
                                              </div>
                                          ) : (
                                            
                                              <h6>
                                                
                                                  {" "}
                                                  {/* <i className="fa fa-rupee"></i>{" "} */}
                                                  {plan.plan_price===""|| plan.plan_price===null ?(<div>{plan.offer_name}
                                                  <br/>
                                                  </div>):(<div><span style={{fontSize:"20px"}}><i className="fa fa-rupee"></i>{plan.plan_price}</span><br/><br/>{plan.offer_name}</div>)}

                                              </h6>
                                          )}

                                          {/* Display popular banner */}
                                          {plan.is_popular === "1" ? (
                                              <img
                                                  src={PopularIcon}
                                                  alt="Popular"
                                                  className="popular_banner"
                                              />
                                          ) : (
                                              ""
                                          )}
                                      </div>

                                      <div className="package_body">
                                      <div className="text-center">
                                              {!is_auth ? (
                                                  plan.plan_price===null ?<a
                                                  href="tel:9019925844"
                                                  className="abroad_btn text-white"
                                                  role="button"
                                                  onClick={() =>
                                                      this._handleAddRemoveCart(
                                                          index,
                                                          plan.id
                                                      )
                                                  }>
                                                  {" "}
                                                  <i className="fa fa-phone mr-1"></i>{" "}
                                                  Call  To Enroll Now{" "}<br/>
                                              </a> :<a
                                                  href="tel:9019925844"
                                                  className="abroad_btn text-white"
                                                  role="button"
                                                  onClick={() =>
                                                      this._handleAddRemoveCart(
                                                          index,
                                                          plan.id
                                                      )
                                                  }>
                                                  {" "}
                                                  <i className="fa fa-phone mr-1"></i>{" "}
                                                  Call  To Enroll Now{" "}<br/>
                                              </a>
                                              ) : plan.cart_status ? (
                                                <a
                                                href="tel:9019925844"
                                                className="abroad_btn text-white"
                                                role="button"
                                                onClick={() =>
                                                    this._handleAddRemoveCart(
                                                        index,
                                                        plan.id
                                                    )
                                                }>
                                                {" "}
                                                <i className="fa fa-phone mr-1"></i>{" "}
                                                Call  To Enroll Now{" "}<br/>
                                            </a>
                                              ) : (
                                               plan.plan_price===null ?<a
                                               href="tel:9019925844"
                                               className="abroad_btn text-white"
                                               role="button"
                                               onClick={() =>
                                                   this._handleAddRemoveCart(
                                                       index,
                                                       plan.id
                                                   )
                                               }>
                                               {" "}
                                               <i className="fa fa-phone mr-1"></i>{" "}
                                               Call  To Enroll Now{" "}<br/>
                                           </a> :<a
                                                  href="tel:9019925844"
                                                  className="abroad_btn text-white"
                                                  role="button"
                                                  onClick={() =>
                                                      this._handleAddRemoveCart(
                                                          index,
                                                          plan.id
                                                      )
                                                  }>
                                                  {" "}
                                                  <i className="fa fa-phone mr-1"></i>{" "}
                                                  Call  To Enroll Now{" "}<br/>
                                              </a>
                                                  
                                              )}
                                          </div>
                                          <br/>
                                          {plan.benefits &&
                                          plan.benefits.length > 0
                                              ? plan.benefits.map(benefit => (
                                                    <p
                                                        className="benefit_true"
                                                        key={benefit.id}>
                                                        <i className="fa fa-check mr-1"></i>{" "}
                                                        {benefit.benefit}{" "}
                                                    </p>
                                                ))
                                              : null}
                                          
                                      </div>
                                  </div>
                              </div>
                          ))
                        : ""}
                </div>
            </div>
        );
    }
}

const mapStatesToProps = state => {
    return {
        is_auth: state.home.is_auth,
        member_plans: state.list.member_plans
    };
};

const mapActionsToProps = {
    onGetMemberPlans: getMembershipPlans,
    onAddRemoveCart: addRemoveCart
};

export default connect(mapStatesToProps, mapActionsToProps)(Pricing);
