import React,{useEffect,useState} from 'react'
import { useSelector, useDispatch } from "react-redux";
import server from '../../utils/Server';
import {Link } from 'react-router-dom';
// import { PDFDownloadLink } from '@react-pdf/renderer';
import NeetPgCollegePredictor from '../NeetPgCollegePredictor';
import './Predictedcolleges.css';
import {
    getAutocompleteColleges,
    hideMessage,
    setAuthUser,
    userLogin,
    userLogout,
    getCartStatus,
    mainLoadingTrue,
    mainLoadingFalse,
    
} from "../../actions/Home";
import thumb from '../../assets/thumbs.png'
import './Predictedcolleges.css';

const PredictedColleges = () => {
    const [counter,setCounter]=useState(0);
    const [predictedColleges,setPredictedColleges]=useState([])
    const [pdf_link,setPdfLink]=useState('');
    const dispatch = useDispatch();
    const [paid,setPaid]=useState(0);
    const [disabled,setDisabled]=useState(false);
    const [showModal, setShowModal] = useState(false); // Modal state

    let rank =localStorage.getItem('rank');
    let auth_id=localStorage.getItem('auth_id')
    let course_id=localStorage.getItem('course_id')
    let cut_off_category_id=localStorage.getItem('cut_off_category_id');
    let fees=localStorage.getItem('fees');
    
    // console.log({fees,rank,state_id,course_id,cut_off_category_id})
    

    const {is_auth,auth_user,streams,levels}= useSelector((state) => ({
        is_auth: state.home.is_auth,
        auth_user: state.home.auth_user,
        streams: state.list.streams,
        levels: state.list.course_levels
      })); 
      const data={
        rank:rank,
        auth_id:auth_id,
        course_id:course_id,
        cut_off_category_id:cut_off_category_id,
        fees:fees,
        user_id:auth_user.id,
        authority:"State Counselling Authority"
      }
      
    useEffect(()=>{
      var formdata=new FormData();
      formdata.append('page',3);
      server.post('/counter',formdata)
      .then((response) => {
       console.log(response.data.message);
      })
        server.get(`/get_user_details/${auth_user.id}`)
        .then((response)=>{
          console.log("counter",response.data.auth_user.cutoff_counter)
          console.log('paid',response.data.auth_user.paid_status)
          if(response.data.auth_user.paid_status && response.data.auth_user.paid_status!==""){
          setPaid(response.data.auth_user.paid_status)  
          }
          if(response.data.auth_user.cutoff_counter && response.data.auth_user.cutoff_counter){
          setCounter(response.data.auth_user.cutoff_counter)
          }
          if(response.data.auth_user.cutoff_counter >1 && (response.data.auth_user.paid_status === null || response.data.auth_user.paid_status === 0)){
            window.location.hash="/predictor_form_payment"
          }
          
          
        })
       
      let formData=new FormData()
      formData.append('rank',rank)
      formData.append('auth_id',auth_id)
      formData.append('course_id',course_id)
      formData.append('cut_off_category_id',cut_off_category_id)
      formData.append('fees',fees)
      formData.append('user_id',auth_user.id)
      
      server.post('/new_college_predictor',formData)
      .then((response)=>{
            
     setPredictedColleges(response.data.cutoff_colleges)
     
      })
 
     

   
    },counter)
   
    const firstStateName = predictedColleges.length > 0 ? predictedColleges[0].author_name : 'No Authority Available';
    const speciality=predictedColleges.length > 0 ? predictedColleges[0].course_name : 'No Speciality Available';
    const category=predictedColleges.length > 0 ? predictedColleges[0].category : 'No Category Available';
    const downloadPDF = () => {
      
      setDisabled(true)
        const userId = localStorage.getItem('user_id'); // Get user ID from local storage
        const rank = localStorage.getItem('rank');
        const auth_id = localStorage.getItem('auth_id');
        const courseId = localStorage.getItem('course_id');
        const categoryId = localStorage.getItem('cut_off_category_id');
        const fees = localStorage.getItem('fees');
        
      dispatch(mainLoadingTrue)
        let formData=new FormData()
        formData.append('rank',rank)
        formData.append('auth_id',auth_id)
        formData.append('course_id',course_id)
        formData.append('cut_off_category_id',cut_off_category_id)
        formData.append('fees',fees)
        formData.append('user_id',auth_user.id)
        
        server.post('https://api.prod.goocampus.in/api/user/cutoff_generate', formData)
          .then((response) => {
            
            let url=response.data.pdf_url;
            setDisabled(false)
            dispatch(mainLoadingFalse)
             window.open(url,"_blank");
          })
          .catch((error) => {
            console.error('Error generating PDF:', error);
          });
      };

  
    return (
  

<div className="mt-5 pt-5 w-100 predictor_bg_blue_color predicted_college_main_div" >
      <div className="row ">
        <div className="col-12 col-md-10 mx-auto">
          <div className='desktop_header'>
          <div className="d-flex justify-content-between align-items-center bg-white  px-3 py-2 info_header_box" >
            <strong><h5 className='mt-2'>{rank} | {firstStateName} | {speciality} | {category} | ₹ {fees} <Link to={"/predictor"}  className="btn text-white py-2 px-5 rounded" style={{backgroundColor:"#F47523"}} >Change</Link></h5></strong>
            
          </div>
          </div>
          <div className="mobile_header bg-white py-3">
            <div className='col-md-12 mt-1'>
              <span>Rank: </span><span>{rank}</span>
            </div>
            <div className='col-md-12 mt-1'style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
              <span>Authority: </span><span>{firstStateName}</span>
            </div>
            <div className='col-md-12 mt-1' style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
              <span>Speciality: </span><span >{speciality}</span>
            </div>
            <div className='col-md-12 mt-1'>
              <span>Category: </span><span>{category}</span>
            </div>
            <div className='col-md-12 mt-1'>
              <span>Budget: </span><span>{fees}</span><Link to={"/predictor"}  className="text-white text-center float-right p-2 rounded" style={{backgroundColor:"#F47523",fontSize:"9px",width:"51px",height:"28px"}} >Change</Link>
            </div>
          </div>
          <div className="alert border my-3 py-4 text-center" style={{background:"#E8F0EE",border:"2px solid #E8F0EE"}}  role="alert">
            <div ><strong><i class="fa fa-info-circle" aria-hidden="true"></i> 
            &nbsp;Disclaimer</strong></div>
             Please be aware that the college is shown based on the rank you’ve provided, college details may vary. Kindly make sure to check the actual cutoff from the respective counselling authority's official website.
          </div>

          <div className="bg-white p-3 shadow-sm mb-3" style={{borderRadius:"10px"}}>
            
          <div className="row pl-4 mid_info_display_desktop" style={{borderRadius:"10px"}}>
            <div className='col-md-1 text-center'>
            <img src={thumb} width="75" height="75"/>
            </div>
            <div className='col-md-7 mt-2 text-left predicted_display_header'>
              
            {predictedColleges.length>0?<h3 className='text-center pl-3 mt-2'>
             Hi <strong>{auth_user.fname}</strong>, your <strong className="" style={{color:"#F47523"}}>NEET-PG Rank is {rank}, </strong>& Your Predicted Colleges are listed below
            </h3>:<h3> Hi <strong>{auth_user.fname}</strong>, your <strong className="" style={{color:"#F47523"}}>NEET-PG Rank is {rank}, </strong>Please select different category options to see the best possible seat for your Rank/Fee/Speciality.
            View list of all categories carefully.</h3>}
            </div>
            <div className='col-md-4'>
            <div className="text-center mt-2">
            {paid === 1 && predictedColleges.length > 0 ? (
  <button 
  onClick={downloadPDF} 
  className="px-4 change_button w-50 text-white" 
  disabled={disabled} 
  
  style={{ backgroundColor: disabled ? "#ccc" : "#F47523" }}
>
  Download
</button>
) : (
  predictedColleges.length > 0 ? (
    <Link 
      className="btn px-4 w-50 change_button text-white" 
      style={{ backgroundColor: "#F47523" }} 
      to="/download_payment"
    >
      Download
    </Link>
  ) : null
)}

              
            </div>
            </div>
            </div>
            <div className='mid_info_display_mobile'>
            <div className='d-flex'>
              <div className='w-25 text-center '>
              <img  src={thumb} width="40" height="40"/>
              </div>
              <div className='w-75 predicted_display_header'>
              {predictedColleges.length>0?<p className='text-left'>
             Hi <strong>{auth_user.fname}</strong>, your <strong className="" style={{color:"#F47523"}}>NEET-PG Rank is {rank}, </strong>& Your Predicted Colleges are listed below
            </p>:<p>Hi <strong>{auth_user.fname}</strong>, your <strong className="" style={{color:"#F47523"}}>NEET-PG Rank is {rank}, </strong>Please select different category options to see the best possible seat for your Rank/Fee/Speciality.
            View list of all categories carefully.</p>}
            <div className="w-100 mt-3">
            {paid === 1 && predictedColleges.length > 0 ? (
  <button 
    onClick={downloadPDF} 
    className="px-4 change_button text-white" 
    disabled={disabled} 
    style={{ backgroundColor: disabled ? "#ccc" : "#F47523" }}
  >
    Download
  </button>
) : (
  predictedColleges.length > 0 ? (
    <Link 
      className="btn px-4 w-25 change_button text-white" 
      style={{ backgroundColor: "#F47523" }} 
      to="/download_payment"
    >
      Download
    </Link>
  ) : null
)}


              
            </div>
          
              </div>
            </div>
            </div>
            

            {predictedColleges.map((college, index) => (
             <Link
             target="_blank"
             to={
                 "/college_details/" +
                 college.col_name.replace(
                     / /g,
                     "+"
                 )
             }>
            
          <div key={index} className="card my-4 college_card">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div className="col-2 text-center">
                      <img  src={"https://api.prod.goocampus.in/"+college.col_logo} alt={college.col_name} width="150" height="150"  />
                    </div>
                
                    <div className="col-10">
                        <div className='row'>
                            <div className='col-md-12'style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                              <b className='text-dark'>{college.col_name}</b>
                            </div>
                            
                            
                           
                        </div>
                        <div className='row my-3'>
                        <div className='col-md-3'style={{ color:"black" , whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} >Rank: {college.cutoff_rank}</div>
                        <div className='col-md-3'style={{ color:"black" , whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>Category: {college.category}</div>
                        <div className='col-md-3'style={{ color:"black" , whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>Fee: {college.course_fees!==0?college.course_fees:"N/A"}</div>
                        <div className='col-md-3'style={{ color:"black" , whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>Round: {college.round}</div>
                        </div>
                        <div className='row'>
                            <div className='col-md-4' style={{ color:"black" , whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>Quota: {college.display_quota}</div>
                            <div className='col-md-4'style={{ color:"black" , whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}> Authority: <a className='text-dark' href={college.author_website} target="_blank" onClick={(e)=>e.stopPropagation()}>{college.author_name}</a></div>
                            <div className='col-md-4'style={{ color:"black" , whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>Speciality: {college.course_name}</div>
                            </div>
                     
                    </div>
                    {/* <div className="col-3 text-right">
                      <p className="mb-0"><strong>Cutoff Rank:</strong> {college.cutoff_rank}</p>
                      
                    </div> */}
                  </div>
                </div>
              </div></Link>
            ))}
{predictedColleges.map((college, index) => (
 <Link
 target="_blank"
 to={
     "/college_details/" +
     college.col_name.replace(
         / /g,
         "+"
     )
 }>
 
 <div className='mobile-cards mt-3 rounded ' style={{boxShadow:"0px 4px 12px rgba(0, 0, 0, 0.1"}}>

    <div className='d-flex'>
      <div className='w-25 py-2'>
        <img className='ml-2' src={"https://api.prod.goocampus.in/"+college.col_logo} alt={"college_logo"} width="50" height="50" />
      </div>
      <div className='w-75'>
        <div style={{ color:"black" ,
          fontSize: "12px",
          display: "-webkit-box",
          WebkitLineClamp: 2,
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
          textOverflow: "ellipsis"
        }}><strong>{college.col_name}</strong></div>
        
        <div className='mt-1' style={{ color:"black" , fontSize:"8px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>Speciality: {college.course_name}</div>
        <div className='mt-1' style={{ color:"black" , fontSize:"8px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>Authority: <a className='text-dark' target="_blank" href="https://goocampustesting.com/" onClick={(e)=>e.stopPropagation()}>{college.author_name}</a></div>
        <div className='mt-1' style={{ color:"black" , fontSize:"8px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>Category: {college.category}</div>
        <div  className="d-flex mt-1" style={{ color:"black" ,fontSize:"8px"}}>
          
         
          <div style={{ color:"black" ,width:"50%"}}>Fee: {college.course_fees!==0?college.course_fees:"N/A"}</div>
         
          <div style={{ color:"black" ,width:"50%"}}>Cutoff Rank: {college.cutoff_rank}</div>
          </div>
      </div>
    </div>
  </div></Link>
))}

            <div className="text-center mt-4">
              {paid === 1 && predictedColleges.length > 0 ? (
  <button 
    onClick={downloadPDF} 
    className="px-4 change_button text-white" 
    disabled={disabled} 
    
    style={{ backgroundColor: disabled ? "#ccc" : "#F47523" }}
  >
    Download
  </button>
) : (
  predictedColleges.length > 0 ? (
    <Link 
      className="btn px-4 w-25 change_button text-white" 
      style={{ backgroundColor: "#F47523" }} 
      to="/download_payment"
    >
      Download
    </Link>
  ) : null
)}

              
            </div>
          </div>
        </div>
      </div>
    </div>

    
  )
}

export default PredictedColleges