import React from "react";
import "./Home.css";
import { Link } from "react-router-dom";
import Autocomplete from "../Autocomplete/HomeSearchAutocomplete";
import Rated from '../../assets/Rated.png';

import { connect } from "react-redux";
import {
    getAutocompleteColleges,
    getHomeDetails,
    addRemoveFavourite
} from "../../actions/Home";
import $ from "jquery";
import { setCallbackValues } from "../../actions/Callbacks";
import Achivements from '../../assets/acheivements.gif';
import SearchImage from "../../assets/search.png";
import NeetUgCounselling from '../../assets/neet-ug-link.png';
import NeetPgCounselling from '../../assets/1.png';
import calendarIcon from '../../assets/calendaricon.png';
import personalcounselling from '../../assets/kg.png';
import Image1 from '../../assets/slider4.jpeg';
import Image2 from '../../assets/slider3.jpeg';
import Image3 from '../../assets/slider5.jpeg';
import Image4 from '../../assets/slider2.jpeg';
import slidericon13 from '../../assets/icons_GCIndia-13.png';
import slidericon2 from '../../assets/icons_GCIndia-02.png';
import slidericon3 from '../../assets/icons_GCIndia-03.png';
import slidericon4 from '../../assets/icons_GCIndia-04.png';
import slidericon5 from '../../assets/icons_GCIndia-05.png';
import slidericon6 from '../../assets/icons_GCIndia-06.png';
import slidericon7 from '../../assets/icons_GCIndia-07.png';
import slidericon8 from '../../assets/icons_GCIndia-08.png';
import slidericon9 from '../../assets/icons_GCIndia-09.png';
import slidericon10 from '../../assets/icons_GCIndia-10.png';
import slidericon11 from '../../assets/icons_GCIndia-11.png';
import slidericon12 from '../../assets/icons_GCIndia-12.png';
import usaimg from '../../assets/usa.png';
import australia  from '../../assets/australia.png';
import uk from '../../assets/uk.png';
import mapimage from '../../assets/Mapofindia.png';
import Icon1 from'../../assets/icon1.png';
import Icon2 from'../../assets/icon2.png';
import Icon3 from'../../assets/icon3.png';
import Icon4 from'../../assets/icon4.png';
import Slider1 from '../../assets/Slider1.jpg';
import Slider2 from '../../assets/slider2.png';
import Clients from '../../assets/Clients.png';
import RatedGif from '../../assets/Clients.gif';
import ServiceImage1 from '../../assets/ServiceImage1.png';
import ServiceImage2 from '../../assets/ServiceImage2.png';
import ServiceImage3 from '../../assets/ServiceImage3.png';
import ServiceImage4 from '../../assets/ServiceImage4.png';
import ServiceImage5 from '../../assets/ServiceImage5.png';
import collegpredictorimg from '../../assets/college_predictor.png';
import Calendar from '../../assets/Calender.png';
import RankPredictor from '../../assets/Rank_predictor.png';
import RankPredictorImage from "../../assets/rank_predictor_background_image.png";
import maleDoctor from '../../assets/unisexdoctor.jpg';
import NewsIcon from "../../assets/news.png";
import CalendarIcon from "../../assets/event_calendar.png";
import ComapareIcon from "../../assets/compare.png";
import CollegesIcon from "../../assets/colleges.png";
import CutoffIcon from "../../assets/cutoff.png";
import VideoIcon from "../../assets/videos.png";
import AbroadIcon from "../../assets/abroad.png";
import UKIcon from "../../assets/uk.png";
import ReviewsIcon from "../../assets/review.png";
import AppScreen from "../../assets/app_screen.png";
import PlayStore from "../../assets/play_store.png";
import AppStore from "../../assets/app_store.png";
import QRCode from "../../assets/qr_code.png";
import ukcarrerfair from '../../assets/ukcareerfair.png';
import BGimages from "../../assets/goocampus_bg_nw.png";
import YouTube from "react-youtube";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { size } from "lodash";
import { getFilterNewsUpdates } from "../../actions/NewsUpdates";
import server from "../../utils/Server";
import whitelogo from '../../assets/white_logo.png';
import homepagedoctor from '../../assets/homepagedoctor.png';
import doctorproperties from '../../assets/doctor_properties.png';
import cap from '../../assets/cap.png';
import searchvector from '../../assets/search_vector.png';
import doctorbrain from '../../assets/doctor_brain.png';
import watsapp from '../../assets/watsapp.png';
import pad from '../../assets/pad.png';
import handstogether from '../../assets/hands_together.png'
import freetrail from '../../assets/free_trail.png';
import oneonecounselling from '../../assets/oneononecounselling.png';
import youtube_mobile from '../../assets/youtube_mobile.png';
import arrow from '../../assets/arrow.png';
import arrowdown from '../../assets/arrowdown.png';
import arrowfirst from '../../assets/arrowfirst.png';
import arrowtangled from '../../assets/arrow_tangled.png';
import featuredoctor from '../../assets/feature_doctor.png';
import feature1 from '../../assets/feature1.png';
import feature2 from '../../assets/feature2.png';
import feature3 from '../../assets/feature3.png';
import feature4 from '../../assets/feature4.png';
import doctor_tools from '../../assets/dootor_tools.png';
import limitedtimeoffer from '../../assets/limted_time_offer.png';
import predictorsteps from '../../assets/predictor_steps.png';
;class Home extends React.Component {
    constructor(props) {
        super(props);
       var formdata=new FormData();
       formdata.append('page',1);
       server.post('/counter',formdata)
       .then((response) => {
        console.log(response.data.message);
       })
        localStorage.setItem('route','');
var count=parseInt(localStorage.getItem('otp_refresh'));
if(count===0){
    count=count+1;
  localStorage.setItem('otp_refresh',1);  
window.location.reload();

}
        this.state = {
            display_state: "Karnataka",
            callback_college: "",
            callback_state: "",
            popular_states_display: "",
            modalState: true
        };
      

        this.handleShow =this.handleShow.bind(this);
        this.changeStateHandler = this.changeStateHandler.bind(this);
        this.handleCallback = this.handleCallback.bind(this);
        this.displayState = this.displayState.bind(this);
    }
    
    handleShow() {
        
        this.setState({ modalState: !this.state.modalState });
       
}

    
    componentWillMount = () => {
        this.props.onGetHomeDetails();
        this.props.onGetFilterNewsUpdates("");
    };

    componentDidMount = () => {
        setInterval(this.displayState, 2400);
       
    };

    displayState = () => {
        let states = this.props.college_states;

        if (states && states.length > 0) {
            // Fetching random states to display
            let randIndex = Math.floor(Math.random() * states.length);

            this.setState({ display_state: states[randIndex].state_name });
        }
    };

    changeStateHandler = e => {
        if (e.target.name === "c_state") {
            this.props.onGetAutocompleteColleges("?state_id=" + e.target.value);
        }
    };
    changeFilters = e => {
        this.setState({ active_page: 1 });
        var filtersData = $("#college_filters_form").serialize();
        var pageData = $("#college_pagination_form").serialize();
        var streams_data = "",
            levels_data = "",
            fmge_data = "";

        $(".streams_check:checked").each(function (index) {
            streams_data +=
                index > 0 ? "," + $(this).val() : "" + $(this).val();
        });

        $(".levels_check:checked").each(function (index) {
            levels_data += index > 0 ? "," + $(this).val() : "" + $(this).val();
        });

        $(".fmge_check:checked").each(function (index) {
            fmge_data += index > 0 ? "," + $(this).val() : "" + $(this).val();
        });

        pageData +=
            "&c_page_no=1&c_streams=" +
            streams_data +
            "&c_levels=" +
            levels_data +
            "&c_fmge=" +
            fmge_data;
        this.props.onGetFilterColleges("?" + filtersData + "&" + pageData);
    };

    
    submitSearchHandler = e => {
        var params = $("#college_search_form").serialize();
        this.props.history.push({
            pathname: "/college_filters",
            search: "?" + params
        });
    };

    handleCallback = (state, college, stream, course_level) => {
        // Show side callback panel
        $(".callback_container")
            .removeClass("d-none")
            .removeClass("slideOutLeft")
            .addClass("slideInLeft");

        this.props.onSetCallbackValues(state, college, stream, course_level);
    };
   
    handleFavouriteCollege = college_id => {
        if (this.props.is_auth && this.props.auth_user) {
            this.props.onAddRemoveFavourite(college_id);
            $("#fav_icon_" + college_id)
                .toggleClass("fa-heart-o")
                .toggleClass("fa-heart");
        } else {
            // Show login
            $(".login_container")
                .removeClass("d-none")
                .removeClass("slideOutRight")
                .addClass("slideInRight");
        }
    };

    
    render() {
        
        const {
            autocomplete_colleges,
            college_states,
            popular_colleges,
            favourite_colleges,
            is_auth,
            auth_user,
            img_url,
            news_updates,
        } = this.props;
        console.log(news_updates)
    let news=[];
    for(var i=0;i<news_updates.length;i++)
    {
        
        if(i<4){
        news.push(<div className="ml-5" >
                                <h5 className="text-left text-dark"><b style={{fontWeight:"bold"}}>{news_updates[i].news_title}</b></h5>
                                  <p className="text-left text-dark">{news_updates[i].brief_descr}</p>
            
                                
                                 <Link  to={
                                                        "/news_details/" +
                                                        news_updates[i].id
                                                    } href="#/news_updates" role="button" className="btn bg-orange text-white float-left news-btn">Read More</Link>
            
                               
        </div>)
    }
   
    }
         console.log(news)
        const { display_state } = this.state;
        const opts = {
            height: "300",
            width: "100%",
            playerVars: {
                autoplay: 0
            }
        };
const learnMore=()=>{
    // window.location.href="https://goocampus.fillout.com/t/qb67BMeGYtus"
    window.open("https://goocampus.fillout.com/t/qb67BMeGYtus", "_blank");

}
   
const redirect=()=>{
    window.location.hash="/predictor"
}
return (
            <div className="home_container pt-2" id="home_container">
                {/* <div className="row mt-5" style={{backgroundColor:"#233974"}}>
                   
                </div> */}
                
                <div className="row" style={{padding:"0px 5%",backgroundColor:"#233974"}}>
                <div className="col-md-12 mt-5 home_logo_div">
                    <img className="home_logo" src={whitelogo} />
                    </div>
                    <div className="col-md-6 pt-4">
                        <h1 style={{fontWeight:"bold"}} className="text-white display-4 home-text"><strong>Find Your Ideal</strong></h1>
                        <h1 style={{fontWeight:"bold"}} className="text-white display-4 home-text"><strong>NEET PG College</strong></h1>
                        <h1 style={{fontWeight:"bold"}} className="text-white display-4 home-text"><strong>In<span style={{color:'#F47523'}}> Minutes!</span></strong></h1>
                        <p className="text-light home-text-desc" style={{fontSize:"20px"}}>Unlock personalised college predictions based on your NEET PG rank. With GooCampus NEET PG College Predictor, make informed decisions and find the best fit for your future!</p>
                        <br/>
                        <div className="py-5 text-left home_start_desktop_button">
                          <button className="ml-4 w-50 p-3 border-0 rounded" onClick={redirect} style={{backgroundColor:"#ff6701",color:"white"}}><h3 className="text-white font-weight-bold mt-1">Try the Tool now <i class="fa fa-chevron-circle-right" aria-hidden="true"></i></h3></button>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <img className="home_image_banner" src={homepagedoctor} width="100%"  height="720" alt="home_page"/>
                        <div className="py-5 text-center home_start_mobile_button">
                          <button className="p-2 border-0 rounded" onClick={redirect} style={{backgroundColor:"#ff6701",color:"white"}}><h3 className="text-white font-weight-bold mt-1">Try the Tool now <i class="fa fa-chevron-circle-right" aria-hidden="true"></i></h3></button>
                        </div>
                    </div>
                </div>          
                
              
<div className="row bg-white px-2 mt-5 pt-5">
<div className="col-md-4 p-0">
    <img style={{objectFit:"cover"}} src={doctorproperties} width={"100%"}  alt="doctor_properties"/>
</div> 
<div className="col-md-8 pt-3 features_margin_right">
    <h1 className="text-left font-weight-bold predictor_benfits_head_desktop" style={{color:"#233974"}}>Benefits of using Goocampus<strong style={{color:'#F47523'}}></strong></h1>
    <h1 className="text-left font-weight-bold predictor_benfits_head_desktop" style={{color:'#F47523'}}>NEET PG Predictor</h1>
    <h1 className="text-left font-weight-bold predictor_benfits_head_mobile text-center" style={{color:"#233974"}}><strong>Benefits of using Goocampus</strong><span style={{color:'#F47523'}}> NEET PG Predictor</span></h1>
    <div className="row px-2 mt-3">
        <div className="col-md-6">
                        <div className="d-flex my-3">
                        <div className="w-25 text-center">
                        <img style={{objectFit:"cover",borderRadius:"0"}} src={cap} className="w-75" alt="cap" />
                 </div>   
                 <div className="w-75 mt-3">
                 <h5 className="predictor_blue_color"><strong>Identify Colleges and Specialties</strong></h5>
                 <p className="predictor_blue_color properties_font" >Discover potential colleges and specialties based on last year's cut-offs.</p>
                 </div>   
            </div>
        

                        <div className="d-flex my-3">
                        <div className="w-25 text-center">
                        <img style={{objectFit:"cover",borderRadius:"0"}} src={searchvector} className="w-50" alt="cap" />
                 </div>   
                 <div className="w-75">
                 <h5 className="predictor_blue_color"><strong>Simplified Research</strong></h5>
                 <p className="predictor_blue_color properties_font">Explore colleges based on your personalised list.</p>
                 </div>   
            </div>
                        <div className="d-flex my-3">
                        <div className="w-25 text-center">
                        

                        <img style={{objectFit:"cover",borderRadius:"0"}} src={doctorbrain} className="w-50" alt="cap" />
                 </div>   
                 <div className="w-75">
                 <h5 className="predictor_blue_color"><strong>Understand Your Chances</strong></h5>
                 <p className="predictor_blue_color properties_font">Gain clarity on admission probabilities across different categories.</p>
                 </div>   
            </div>
                        <div className="d-flex my-3">
                        <div className="w-25 text-center">
                      

                        <img style={{objectFit:"cover",borderRadius:"0"}} src={watsapp} className="w-50" alt="cap" />
                 </div>   
                 <div className="w-75">
                 <h5 className="predictor_blue_color"><strong>WhatsApp Notifications</strong></h5>
                 <p className="predictor_blue_color properties_font">Receive important 2024 counselling updates directly via WhatsApp.</p>
                 </div>   
            </div>

        </div>
        <div className="col-md-6 feature_list_tool">
                    <div className="d-flex my-4">
                    <div className="w-25 text-center">
                  <img style={{objectFit:"cover",borderRadius:"0"}} src={pad} className="features_secon_one" alt="cap" />
                 </div>   
                 <div className="w-75">
                 <h5 className="predictor_blue_color"><strong>Create Your Preference List</strong></h5>
                 <p className="predictor_blue_color properties_font">Rank your preferred colleges and courses easily.</p>
                 </div>   
            </div>
                        <div className="d-flex my-4">
                        <div className="w-25 text-center">
                        

                        <img style={{objectFit:"cover",borderRadius:"0"}} src={handstogether} className="features_secon_one" alt="cap" />
                 </div>   
                 <div className="w-75">
                 <h5 className="predictor_blue_color"><strong>Save Time</strong></h5>
                 <p className="predictor_blue_color properties_font">Quickly find your best options without extensive searching.</p>
                 </div>   
            </div>
                        <div className="d-flex my-4">
                        <div className="w-25 text-center">
                        
                        
                        <img style={{objectFit:"cover",borderRadius:"0"}} src={freetrail} className="features_secon_one" alt="cap" />
                 </div>   
                 <div className="w-75">
                 <h5 className="predictor_blue_color"><strong>Free Trial</strong></h5>
                 <p className="predictor_blue_color properties_font">Experience the service risk-free.</p>
                 </div>   
            </div>
                        <div className="d-flex my-4">
                 <div className="w-25 text-center">
                 <img style={{objectFit:"cover",borderRadius:"0"}} src={oneonecounselling} className="features_secon_one" alt="cap" />
                 </div>   
                 <div className="w-75">
                 <h5 className="predictor_blue_color"><strong>One-on-One Counseling</strong></h5>
                 <p className="predictor_blue_color properties_font">Get personalised guidance from a counsellor to navigate the process.</p>
                 </div>   
            </div>
        </div>
    </div>
</div>
</div>

<div className="row px-5 mb-0 pb-0 bg-white">
    <div className="col-md-6">
<h2 style={{fontWeight:"bold",width:"60%"}} className="predictor_bg_blue_color text-white py-2 mt-5 tutorial_header" > How to use GooCampus</h2>
<h2 style={{fontWeight:"bold"}} className="predictor_bg_blue_color text-white py-2 text-left tutorial_header_second_one"> NEET College Predictor</h2>
<div className="text-center">
<img style={{transform:"Rotate(30deg)",marginTop:"5%",borderRadius:"0"}} className="arrowtangled" src={arrowtangled} width="200"/>
</div>
    <iframe className="iframe" width="100%" src="https://www.youtube.com/embed/XaIbx4sQFLU?si=Vtb71Y6VAKGlva9l" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
   
    </div>
    <div className="col-md-6">
    <img src={youtube_mobile} style={{objectFit:"cover"}} alt="youtube mobile"/>
        
    </div>
</div>
<div className="row bg-white pt-5">
    <div className="col-md-12">
    <h1 className="text-center font-weight-bold display-3 how_it_works">How <span style={{color:"rgb(242 118 46)"}}>It Works</span></h1>
    </div>

</div>

<div className="row py-5 predictor_steps_desktop" style={{background:"rgba(255, 238, 230, 0.94)"}}>
   <div className="row pl-5 ml-5 mb-5 pb-5 m-auto" >
  <div className="col-md-10 mb-5">
    <div className="row">
     <div className="col-md-1" >
     <div className="circle-step">1</div>
    </div>       
     <div className="col-md-3">
           <img style={{objectFit:"cover",transform:"rotate(180deg)"}} src={arrowfirst} width="100%" /> 
    </div>  
         
     <div className="col-md-1 text-left">
     <div className="circle-step">2</div>
     </div>
     <div className="col-md-3 mt-3">
        <img style={{objectFit:"cover",transform:"rotate(0deg)"}} src={arrowdown} width="100%" /> 
    </div>       
     <div className="col-md-1">
     <div className="circle-step">3</div>
     </div>       
     <div className="col-md-3 pt-4"> <img style={{objectFit:"cover",transform:"rotate(200deg)"}} src={arrowfirst} width="100%" /> </div>       
     </div>
     <div className="row w-100 " style={{position:"absolute",left:"-1%"}}>
        <div className="col-md-4">
            <h4 className="predictor_blue_color">Enter your<br/>     NEET PG rank</h4>
        </div>
        <div className="col-md-4">
        <h4 className="predictor_blue_color">Choose your preferred speciality</h4>
        </div>
        <div className="col-md-4">
        <h4 className="predictor_blue_color">Select a convenient <br/>
        fee structure</h4></div>
     </div>
     <div className="row" style={{marginTop:"15%"}}>
     <div className="col-md-1" >
     <div className="circle-step">7</div>
    </div>       
     <div className="col-md-3">
           <img style={{objectFit:"cover",transform:"rotate(0deg)"}} src={arrowfirst} width="100%" /> 
    </div>  
         
     <div className="col-md-1 text-left">
     <div className="circle-step">6</div>
     </div>
     <div className="col-md-3">
        <img style={{objectFit:"cover",transform:"rotate(180deg)"}} src={arrowdown} width="100%" /> 
    </div>       
     <div className="col-md-1">
     <div className="circle-step">5</div>
     </div>       
     <div className="col-md-3"> 
        
        <img style={{objectFit:"cover",transform:"rotate(333deg)"}} src={arrowfirst} width="100%" /> </div>       
     </div>
     <div className="row w-100 mt-3" style={{position:"absolute",left:"-1%"}}>
        <div className="col-md-4">
            <h4 className="predictor_blue_color">Download the report 
            for detailed analysis</h4>
        </div>
        <div className="col-md-4">
        <h4 className="predictor_blue_color">Obtain results for Govt, 
Private, Management, 
Deemed, and NRI seatsy</h4>
        </div>
        <div className="col-md-4">
        <h4 className="predictor_blue_color">Indicate your 
        category</h4></div>
     </div>
     </div>
    <div className="col-md-2 my-5 pt-5">
    <div className="circle-step mt-5">4</div> 
    <h4 className="predictor_blue_color">Pick your <br/>
    preferred state</h4>   
    </div>

     
     
               

    </div>
 </div> 
 <div className="row px-5 predictor_steps_div">
    <div className="col-md-12" style={{backgroundColor:"#ffefe7"}}>
        <img src={predictorsteps} width="100%" alt="predictor_steps"/>
        <div className="py-5 text-center home_start_mobile_button">
                          <button className="p-2 border-0 rounded" onClick={redirect} style={{backgroundColor:"#ff6701",color:"white"}}><h3 className="text-white font-weight-bold mt-1">Try the Tool now <i class="fa fa-chevron-circle-right" aria-hidden="true"></i></h3></button>
                        </div>
    </div>
    
 </div>
 <div className="row bg-white py-5">
 
    <button className="m-auto predictor_feature_button py-3 border-0 predictor_bg_blue_color rounded features_top" onClick={redirect} style={{color:"white",fontSize:"30px"}}><h3 className="text-white font-weight-bold">Try the Tool now <i class="fa fa-chevron-circle-right" aria-hidden="true"></i></h3></button>
 
{/* <button className="m-auto predictor_bg_blue_color p-3 text-white w-25"  >Try the tool Now <i class="fa fa-chevron-circle-right" aria-hidden="true"></i></button> */}
 </div>             

<div className="row bg-white">
<div className="col-md-5 feature_doctor_mobile">
    <img src={featuredoctor} width="100%" />
</div>
<div className="col-md-7 pr-5">
    <h1 className="predictor_blue_color display-3 font-weight-bold predictor_feature ">Features</h1>
      <div className="d-flex  my-4">
      <div className="w-25 feature_list_img">
      <img style={{borderRadius:"0"}} src={feature1} width="50" alt="feature1" />
      </div>
      <div className="w-75 feature_list_text">
        <h3 className="predictor_blue_color text-left predictor_features_heading">All College Cut-offs in One Place</h3>
        <p className="predictor_features_desc">Get all the cut-offs of all colleges in one place. No need to search</p>
      </div>
      </div> 
      <div className="d-flex my-4">
      <div className="w-25 feature_list_img">
      <img style={{borderRadius:"0"}}  src={feature2} width="50" alt="feature1" />
      </div>
      <div className="w-75 feature_list_text">
        <h3 className="predictor_blue_color text-left predictor_features_heading">Detailed College Information</h3>
        <p className="predictor_features_desc">Explore course offerings, fee structures, and cut-off trends for each college.</p>
      </div>
      </div> 
      <div className="d-flex my-4">
      <div className="w-25 feature_list_img">
      <img style={{borderRadius:"0"}}  src={feature3} width="50" alt="feature2" />
      </div>
      <div className="w-75 feature_list_text">
        <h3 className="predictor_blue_color text-left predictor_features_heading">Full Cut-off Report</h3>
        <p className="predictor_features_desc">Download a comprehensive report of all available cut-offs for a deeper analysis.</p>
      </div>
      </div> 
      <div className="d-flex my-4">
      <div className="w-25 feature_list_img">
      <img style={{borderRadius:"0"}}  src={feature4} width="50" alt="feature4" />
      </div>
      <div className="w-75 feature_list_text">
        <h3 className="predictor_blue_color text-left predictor_features_heading">Easy-to-Use Interface</h3>
        <p className="predictor_features_desc">Enter your details, and the tool does the rest—no complex inputs are needed.</p>
      </div>
      </div>      

</div>
<div className="col-md-5 feature_doctor_desktop">
    <img src={featuredoctor} width="100%" />
</div>
</div>

<div className="row bg-white predictor_why_our_tool_desktop">
    <div className="col-md-5">
        <img src={doctor_tools} width="100%" alt="doctor_tools"/>
    </div>
    
    <div className="col-md-7 pt-5 mt-5">
    <h1 className="display-3 predictor_blue_color predictor_why_our_tool_header mt-5 pt-5" style={{fontWeight:"bold"}} >Why Our <span style={{color:"rgb(242 118 46)"}}>Tool</span></h1>
   <br/>
    <p className="predictor_blue_color mr-5" style={{fontSize:"30px"}}>
    {/* <span style={{fontWeight:"bold"}}>Choosing the right college is crucial to your medical career. </span> */}
    Choosing the right college is key to your medical career. GooCampus NEET PG College Predictor simplifies the process by providing clear insights into your best-fit options, helping you decide with confidence.

    </p>
    
    </div>
</div>
<div className="row bg-white predictor_why_our_tool_mobile">
   
<div className="col-md-5">
        <img src={doctor_tools} width="100%" alt="doctor_tools"/>
    </div>
    <div className="col-md-7 py-5">
    <h1 className="display-3 predictor_blue_color why_out_tool_header" style={{fontWeight:"bold"}} >Why Our <span style={{color:"rgb(242 118 46)"}}>Tool</span></h1>
    <p className="predictor_blue_color px-5 why_out_tool_desc" style={{fontSize:"30px"}}>
    {/* <span style={{fontWeight:"bold"}}>Choosing the right college is crucial to your medical career. </span> */}
    Choosing the right college is key to your medical career. GooCampus NEET PG College Predictor simplifies the process by providing clear insights into your best-fit options, helping you decide with confidence.

    </p>
    
    </div>
    
</div>

<div className="row  bg-dark-blue">

           
    <div className="col-md-8 text-white py-5 rounded-left">
      <h1 className="font-weight-bold text-white display-2 pricing_heading">Pricing</h1>
      <h4 className="mb-4 text-white display-5 pricing_sub_heading">What You <span className="text-highlight">Get</span></h4>
      <ul className="list-unstyled">
        <li className="mb-3">
          <strong className="text-highlight font-weight-bold">NEET PG College Predictor:</strong><span style={{fontWeight:"lighter"}}> Personalised predictions to help you find the best-fit college based on your rank.</span>
        </li>
        <li className="mb-3">
          
          <strong className="text-highlight font-weight-bold">Counselling Notifications:</strong> <span style={{fontWeight:"lighter"}}>Stay up-to-date with important counselling dates and notifications so you never miss a step.</span>
        </li>
        <li>
          
          <strong className="text-highlight font-weight-bold">1 Session with a Counsellor:</strong><span style={{fontWeight:"lighter"}}> Get expert guidance tailored to your needs and make the right decision for your medical career.</span>
        </li>
      </ul>
    </div>
    <div className="col-md-4 limited_opporturnities">
        <img src={limitedtimeoffer}  width="100%" style={{objectFit:"cover"}} />
    </div>
    <div className="col-md-12 text-center py-4">
        <button className="p-3 border-0 pricing_button" onClick={redirect} style={{backgroundColor:"#ff6701",color:"white"}}><h3 className="text-white font-weight-bold">Try the tool now <i class="fa fa-chevron-circle-right" aria-hidden="true"></i></h3></button>
    </div>
  
</div>
<div className="row bg-white my-5">
    <div className="col-md-12">
    <h1 className="predictor_blue_color text-center font-weight-bold display-3 faq_header">
    FAQ
    </h1>
    </div>
    <div className="col-md-11 m-auto px-5">
        <div className="faq-padding" style={{backgroundColor:"rgb(255 238 229)",borderRadius:"20px"}}>
       <h2 className="font-weight-bold predictor_blue_color text-left  faq_question">1. What information do I need to use the predictor?</h2>
       <p className="faq_description" style={{fontSize:"20px"}}>You only need your NEET PG rank and a few personal preferences like state, category and course interest.</p>

       <h2 className="font-weight-bold predictor_blue_color text-left faq_question">2. Is the predictor free?</h2>
       <p className="faq_description" style={{fontSize:"20px"}}>You can access a free trial of the tool to see how it works. Full access will require a subscription.</p>

       <h2 className="font-weight-bold predictor_blue_color text-left faq_question">3. How accurate is the college prediction?</h2>
       <p className="faq_description" style={{fontSize:"20px"}}>Our tool uses historical data and trends to give you the most accurate predictions possible based on your NEET PG rank.</p>
       <h2 className="font-weight-bold predictor_blue_color text-left faq_question">4. Can I download the results?</h2>
       <p className="faq_description" style={{fontSize:"20px"}}>Yes, you can download a full cut-off report for all the colleges listed.</p>
       </div>
    </div>
    
</div>
<div className="row px-5">
    <div className="col-md-9 text-center m-auto">
        <h1 className="predictor_blue_color font-weight-bold display-4 doubts-header">Got Doubts? We're Here to Help!</h1>
    </div>
    <p className="predictor_blue_color px-5 mb-5 text-center doubts-description" style={{fontSize:"30px"}}>Have questions about the NEET PG College Predictor or need further assistance? Our dedicated support team is ready to guide you every step of the way.</p>

    <h2 className="predictor_blue_color font-weight-bold m-auto doubts_fill_out_header">Just fill out this form, and we'll get in touch !</h2>
    <div className="col-md-12 py-5 text-center">
    <button className="border-0 rounded fillout_button" onClick={learnMore} style={{backgroundColor:"#ff6701",color:"white"}}><h3 className="text-white font-weight-bold">Learn More </h3></button>
    </div>
</div>

</div>

        
        );
    }

    componentWillUnmount() {
        // this.props.onGetAutocompleteColleges('');
        clearInterval(this.displayState);
    }
}

const mapStatesToProps = state => {
    return {
        autocomplete_colleges: state.home.autocomplete_colleges,
        college_states: state.state.college_states,
        popular_colleges: state.home.popular_colleges,
        favourite_colleges: state.home.favourite_colleges,
        is_auth: state.home.is_auth,
        auth_user: state.home.auth_user,
        img_url: state.home.img_url,
        news_updates: state.news.news_updates,
    };
};

const mapActionsToProps = {
    onGetHomeDetails: getHomeDetails,
    onGetAutocompleteColleges: getAutocompleteColleges,
    onAddRemoveFavourite: addRemoveFavourite,
    onSetCallbackValues: setCallbackValues,
    onGetFilterNewsUpdates: getFilterNewsUpdates,
};

export default connect(mapStatesToProps, mapActionsToProps)(Home);
